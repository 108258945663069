import { Switch, Route, BrowserRouter as Router  } from 'react-router-dom';
import React from 'react';
// Containers
const Layout = React.lazy(() => import('../shared/Layout'));

//Spinner
const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
)

const LayoutNavigation=(props)=>{
    return (
        <Router>
            <React.Suspense fallback={loading}>
                <Switch>
                    <Route path="/" name="Layout" render={props => <Layout {...props} />} />
                </Switch>
            </React.Suspense>
        </Router>
    );
}


export default LayoutNavigation;
